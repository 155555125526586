import Cookies from 'js-cookie'
import { Dialog,Notify  } from 'vant';

// 把下拉菜单放入表单莫模板中
export const setSelectItems = function (formInfo, rowName, selectItems) {
  for (var i = 0; i < formInfo.length; i++) {
    if (formInfo[i]['value'] == rowName) {
      formInfo[i]['addtion']['selectItems'] = selectItems
    }
  }
}

export const setSearchSelectItems = function (searchTable, rowName, selectItems) {
  for (var i = 0; i < searchTable.length; i++) {
    let inList = searchTable[i]
    for (let j = 0; j < inList.length; j++) {
      if (inList[j]['value'] == rowName) {
        inList[j]['items'] = selectItems
      }
    }
  }
}

// 判断列表是否包含某个对象
export const arrayContain = function (array, obj) {
  for (var i = 0; i < array.length; i++) {
    var arrayItem = array[i]
    if (arrayItem == obj) {
      return true
    }
  }
  return false
}

/**
 * 把数据库查出的信息放入列表中
 * @param data
 * @param form
 * @param trans
 */
export const saveDataToForm = function (data, form, trans) {
  for (var i = 0, keys = Object.keys(data); i < keys.length; i++) {
    var key = keys[i]
    var newKey = key
    if (trans) {
      if (trans[key]) {
        newKey = trans[key]
      }
    }

    if (typeof data[key] === 'number') {
      form[newKey] = data[key] + ''
    } else {
      form[newKey] = data[key]
    }
  }
}

/**
 * 处理拿到respons后的操作
 * @type {{dealResponse: responseUtil.dealResponse, isResponseSuccess(*): boolean}}
 */
export const responseUtil = {
  /**
   * 最原始的处理response的操作
   * @param that
   * @param response
   * @param success
   * @param error
   */
  dealResponseNative: function (that, response, success, error) {
    if (this.isResponseSuccess(response)) {
      if (success) {
        success()
      }
    } else {
      if (error) {
        error()
      }
    }
  },
  /**
   * 处理response,错误后弹出异常信息
   * @param that
   * @param response
   * @param success
   */
  dealResponse (that, response, success, config) {
    var title = '提示'
    var content = response.data.msg
    if (config) {
      if (config['title']) title = config['title']
      if (config['content']) content = config['content']
    }

    var error = () => {
      //危险通知
      Notify({ type: 'danger', message: content });
    }

    this.dealResponseNative(that, response, success, error)
  },
  /**
   * 成功后弹出提示并直接跳转
   * @param that
   * @param response
   * @param index
   */
  dealResponseAndJump (that, response, index, config) {    var title = '操作成功'
    var content = response.data.msg
    var errorTitle = '提示'
    var errorContent = response.data.msg

    if (config) {
      if (config['title']) title = config['title']
      if (config['content']) content = config['content']
      if (config['errorTitle']) errorTitle = config['errorTitle']
      if (config['errorContent']) errorContent = config['errorContent']
    }

    var errorInfo = {
      title: errorTitle,
      content: errorContent
    }

    // 成功通知
    Notify({ type: 'success', message: title });

    that.$router.push({ name: index })

    this.dealResponse(that, response, success, errorInfo)
  },
  /**
   * 判断是response是否成功
   * @param response
   * @returns {boolean}
   */
  isResponseSuccess (response) {
    if (response.data.code == 0) {
      return true
    }
    return false
  },


  alertErrMsg (obj,msg) {
    // 警告通知
    Notify({ type: 'danger', message: msg });
  },

  alertMsg (obj,msg) {
    // 主要通知
    Notify({ type: 'primary', message: msg });
  },
  alertOK (obj) {
    // 成功通知
    Notify({ type: 'success', message: '操作成功' });
  }
}

/**
 * 获取用户的user_id
 * @returns {string}
 */

export const getStaffId = function () {
  return Cookies.get('staff_id') ? Cookies.get('staff_id') : '-1'
}

export const globaluserId = function () {
    return Cookies.get('user_id') ? Cookies.get('user_id') : '-1'
}

export const saveListInfo = (that, info) => {
  that.tableData = info.child
  that.page.pageCount = info.pageCount
  that.page.totalPage = info.totalpage
}

export const formUtil = {
  addFormItem (that, beforeItemName, addItem) {
    for (var i = 0; i < that.formInfo.length; i++) {
      var obj = that.formInfo[i]
      if (obj.value == beforeItemName) {
        let beforePart = that.formInfo.slice(0, i + 1)
        let endPart = that.formInfo.slice(i + 1, that.formInfo.length)

        that.formInfo = [...beforePart, addItem, ...endPart]

        break
      }
    }
  },

  removeFormItem (that, itemName) {
    for (var i = 0; i < that.formInfo.length; i++) {
      var obj = that.formInfo[i]
      if (obj.value == itemName) {
        that.formInfo.splice(i, 1)
      }
    }
  }

}


// 把list分组
export const list2groupList = function (list, rowCount) {
  var groupList = []//searchTable
  var tempList = []


  for (var i = 0; i < list.length; i++) {
    if (i % rowCount == 0) {
      tempList = []
      groupList.push(tempList)
    }
    var item = list[i]
    tempList.push(item)
  }

  return groupList
}

// 把list分组
export const groupList2list = function (list) {
  var result = []
  for(let i = 0 ; i < list.length ; i++){
    result = [...(list[i]),...result]
  }
  return result
}
/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
export const money = (number) => {
  if(!number) return "0.00"
  var num = "";
  if(number){
    num = (number+ '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,');
    var xsd=num.toString().split(".");
    if(xsd.length==1){
      num=num.toString()+".00";
    }
    if(xsd.length>1) {
      if (xsd[1].length < 2) {
        num = num.toString() + "0";
      }
    }
  }

  return num;
}

/**
 * 反解开EMOJI编码后的字符串 与上对应使用
 * @param str
 * @returns {*}
 */
export const uncodeUtf16 = function (str) {
  var reg = /\&#.*?;/g;
  var result = str.replace(reg, function (char) {
    var H, L, code;
    if (char.length == 9) {
      code = parseInt(char.match(/[0-9]+/g));
      H = Math.floor((code - 0x10000) / 0x400) + 0xD800;
      L = (code - 0x10000) % 0x400 + 0xDC00;
      return unescape("%u" + H.toString(16) + "%u" + L.toString(16));
    } else {
      return char;
    }
  });
  return result;
}



export const objInit = function (obj) {
  var keys = Object.getOwnPropertyNames(obj)
  for(var i = 0 ; i <keys.length ; i++){
    var key = keys[i]
    if(key == '__ob__') continue;
    obj[key] = ""
  }
}

export const backToHomePage = function () {
  Cookies.set('user', '')
  Cookies.set('token', '')
  Cookies.set('user_id', '')
  window.location.href = "#/login"
  // router.push({name: 'login'})
}

export const authButtons = function(buttons,authList){
  var notNullAuthList = authList || []
  var result = buttons.filter(({name})=>{
    return arrayContain(notNullAuthList,name);
  })
  return result
}

var baseValidateCancel = function (form) {
  form.fields.forEach(field => {
    field.validateState=""
  })
}
/**
 * 验证失败后一段时间，validate消失
 * @param form
 */
export const delayValidateCancel = function(form){
  setTimeout(()=>form.fields.forEach(field => {
    field.validateState=""
  }),3000)
}


export const validateCancelNow = function(form){
  if(form instanceof Array)    form.map((item)=>baseValidateCancel(item))
                        else    baseValidateCancel(form)
}

export const checkAuthList = function(authButtonsList,name){
    const checkAuthButtonsList = authButtonsList == undefined ? [] : authButtonsList
    for (var i = 0; i < checkAuthButtonsList.length; i++) {
        if (checkAuthButtonsList[i] == name) {
            return true
        }
    }
    return false
}

/* --------APP地址-------------------------- */
export const checkAndroid = function(buttons,authList){
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    return isAndroid
}
export const checkIOS = function(buttons,authList){
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isiOS
}

//调用android关闭页面方法*******end***********

/********判断H5页面是否在web容器中 start*********/
export const openInWebview = function() {/* 返回true或false; */
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
    return false;
  } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
    return false;
  } else if (ua.match(/WeiBo/i) == "weibo") {
    return false;
  } else {
    if (ua.match(/Android/i) != null) {
      return ua.match(/browser/i) == null;
    } else if (ua.match(/iPhone/i) != null) {
      return ua.match(/safari/i) == null;
    } else {
      return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
    }
  }
}
/********判断H5页面是否在web容器中 end*********/

// 压缩图片  多张
export const dealImage = function(file,base64, w, callback,i,fileLength) {
// dealImage(file,base64, w, callback,i,fileLength) {
  var newImage = new Image();
  var quality = 0.2;    //压缩系数0-1之间
  newImage.src = base64;
  newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
  var imgWidth, imgHeight;
  newImage.onload = function () {
    imgWidth = this.width;
    imgHeight = this.height;
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    // if (Math.max(imgWidth, imgHeight) > w) {
    //     if (imgWidth > imgHeight) {
    //         canvas.width = w;
    //         canvas.height = w * imgHeight / imgWidth;
    //     } else {
    //         canvas.height = w;
    //         canvas.width = w * imgWidth / imgHeight;
    //     }
    // } else {
    canvas.width = imgWidth;
    canvas.height = imgHeight;
    quality = 0.2;
    // }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
    var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
    // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
    // while (base64.length / 1024 > 150) {
    // 	quality -= 0.01;
    // 	base64 = canvas.toDataURL("image/jpeg", quality);
    // }
    // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
    // while (base64.length / 1024 < 50) {
    // 	quality += 0.001;
    // 	base64 = canvas.toDataURL("image/jpeg", quality);
    // }
    callback(file,base64,i,fileLength);//必须通过回调函数返回，否则无法及时拿到该值
  }
}

// 压缩图片  单张
export const dealImageSingle = function(file,base64,callback,type) {
  debugger
  var newImage = new Image();
  var quality = 0.2;    //压缩系数0-1之间
  newImage.src = base64;
  newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
  var imgWidth, imgHeight;
  newImage.onload = function () {
    imgWidth = this.width;
    imgHeight = this.height;
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    canvas.width = imgWidth;
    canvas.height = imgHeight;
    quality = 0.2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
    var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
    callback(file,base64,type);//必须通过回调函数返回，否则无法及时拿到该值
  }
}

export const formatYearMonth = function(value) {
  if (null == value || '' == value) return
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM
}

export const addMonth = function(date, offset) {
  if (date instanceof Date && !isNaN(offset)) {
    let givenMonth = date.getMonth();
    let newMonth = givenMonth + offset;
    date.setMonth(newMonth);
    date.setDate(1);
    return date;
  }
}
